import React, { useState, useEffect } from 'react';
import './Home.css';
import img1 from './media/1.jpg';
import img2 from './media/2.jpg';
import img3 from './media/3.jpg';
import img4 from './media/4.jpg';
import img5 from './media/5.jpg';
import img6 from './media/6.jpg';
import img7 from './media/7.jpg';
import img8 from './media/8.jpg';
import img9 from './media/9.jpg';
import img10 from './media/10.jpg';
import img11 from './media/11.jpg';
import img12 from './media/12.jpg';
import img13 from './media/13.jpg';
import img14 from './media/14.jpg';
import img15 from './media/15.jpg';
import img16 from './media/16.jpg';
import img17 from './media/17.jpg';
import img18 from './media/18.jpg';
import img19 from './media/19.jpg';
import img20 from './media/20.jpg';
import img21 from './media/21.jpg';
import img22 from './media/22.jpg';
import img23 from './media/23.jpg';
import img24 from './media/24.jpg';
import img25 from './media/25.jpg';
import img26 from './media/26.jpg';
import img27 from './media/27.jpg';
import img28 from './media/28.jpg';
import img29 from './media/29.jpg';
import img30 from './media/30.jpg';
import img31 from './media/31.jpg';
import img32 from './media/32.jpg';
import img33 from './media/33.jpg';
import img34 from './media/34.jpg';
import img35 from './media/35.jpg';
import img36 from './media/36.jpg';
import img37 from './media/37.jpg';
import img38 from './media/38.jpg';
import img39 from './media/39.jpg';
import PricePopup from './PricePopup';
const Home = () => {
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [showPricePopup, setShowPricePopup] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleClosePopups = (event) => {
    const imagePopup = document.getElementById('image-popup');
    const pricePopup = document.getElementById('price-popup');
    const isClickInsideImagePopup = imagePopup && imagePopup.contains(event.target);
    const isClickInsidePricePopup = pricePopup && pricePopup.contains(event.target);
  
    // Check if the click occurred outside of the image popup and the clicked element is not the blur background
    if (!isClickInsideImagePopup && !event.target.classList.contains('blur-background') && !isClickInsidePricePopup) {
      setShowImagePopup(false);
      setShowPricePopup(false);
      document.body.classList.remove('blur-background');
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClosePopups);
  
    return () => {
      document.removeEventListener('mousedown', handleClosePopups);
    };
  }, []);

  const handleGetPriceClick = () => {
    setShowPricePopup(true);
    document.body.classList.add('blur-background');
  };

  const handleImageClick = (imgSrc) => {
    setSelectedImage(imgSrc);
    setShowImagePopup(true);
    document.body.classList.add('blur-background');
  };

  return (
    <div>
      <header className="section-heading">
        <h3 className="section-title">Popular products</h3>
      </header>
      <div className="product-container">
      <div className="row">
          <ProductCard imgSrc={img25} altText="Product 17" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
          <ProductCard imgSrc={img26} altText="Product 18" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
          <ProductCard imgSrc={img27} altText="Product 19" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
          <ProductCard imgSrc={img28} altText="Product 20" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
        </div>
        <div className="row">
          <ProductCard imgSrc={img29} altText="Product 17" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
          <ProductCard imgSrc={img30} altText="Product 18" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
          <ProductCard imgSrc={img31} altText="Product 19" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
          <ProductCard imgSrc={img32} altText="Product 20" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
        </div>
        <div className="row">
          <ProductCard imgSrc={img33} altText="Product 17" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
          <ProductCard imgSrc={img34} altText="Product 18" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
          <ProductCard imgSrc={img35} altText="Product 19" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
          <ProductCard imgSrc={img36} altText="Product 20" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
        </div>
        <div className="row">
          <ProductCard imgSrc={img37} altText="Product 17" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
          <ProductCard imgSrc={img38} altText="Product 18" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
          <ProductCard imgSrc={img39} altText="Product 19" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
          <ProductCard imgSrc={img16} altText="Product 16" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
          
        </div>
        <div className="row">
          <ProductCard imgSrc={img17} altText="Product 17" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
          <ProductCard imgSrc={img18} altText="Product 18" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
          <ProductCard imgSrc={img19} altText="Product 19" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
          <ProductCard imgSrc={img20} altText="Product 20" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
        </div>
        <div className="row">
          <ProductCard imgSrc={img21} altText="Product 21" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
          <ProductCard imgSrc={img22} altText="Product 22" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
          <ProductCard imgSrc={img23} altText="Product 23" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
          <ProductCard imgSrc={img24} altText="Product 24" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
        </div>
        <div className="row">
          <ProductCard imgSrc={img1} altText="Product 1" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
          <ProductCard imgSrc={img2} altText="Product 2" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
          <ProductCard imgSrc={img3} altText="Product 3" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
          <ProductCard imgSrc={img4} altText="Product 4" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
        </div>
        <div className="row">
          <ProductCard imgSrc={img5} altText="Product 5" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
          <ProductCard imgSrc={img6} altText="Product 6" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
          <ProductCard imgSrc={img7} altText="Product 7" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
          <ProductCard imgSrc={img8} altText="Product 8" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
        </div>
        <div className="row">
          <ProductCard imgSrc={img9} altText="Product 9" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
          <ProductCard imgSrc={img10} altText="Product 10" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
          <ProductCard imgSrc={img11} altText="Product 11" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
          <ProductCard imgSrc={img12} altText="Product 12" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
        </div>
        <div className="row">
          <ProductCard imgSrc={img13} altText="Product 13" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
          <ProductCard imgSrc={img14} altText="Product 14" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
          <ProductCard imgSrc={img15} altText="Product 15" name="Acrylic Plate" handleImageClick={handleImageClick} handleGetPriceClick={handleGetPriceClick}/>
          
        </div>
      </div>

      {/* Image Popup */}
      {showImagePopup && selectedImage && (
        <>
          <div id="image-popup" className="popup">
            <img src={selectedImage} alt="Selected" />
          </div>
          <div className="overlay" onClick={handleClosePopups}></div>
        </>
      )}

      {/* Price Popup */}
      {showPricePopup && (
        <div id="price-popup">
          <PricePopup onClose={() => setShowPricePopup(false)} />
        </div>
      )}

      {/* Overlay for Image Popup */}
      {showImagePopup && <div className="overlay" onClick={handleClosePopups}></div>}
    </div>
  );
};

const ProductCard = ({ imgSrc, name, altText, handleImageClick, handleGetPriceClick }) => {
  return (
    <div className="product">
      <img src={imgSrc} alt={altText} onClick={() => handleImageClick(imgSrc)} />
      <div className="product-info">
        <h4>{name}</h4>
        <div className="button-container">
          <a href="tel:+917045527555" className="button">Call Now</a>
          <button className="button2" onClick={handleGetPriceClick}>Get Latest Price</button>
        </div>
      </div>
    </div>
  );
};

export default Home;
