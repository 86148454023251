import React from 'react';
import logo from './media/logo.jpg';
import './Header.css';
import phone from './media/phone.jpg';

const Header = () => {
  return (
    <header>
      <section className="header-main">
        <section className="container-h">
          <div className="logo">
            <a href="./">
              <img src={logo} alt='logo' />
            </a>
          </div>
          <div className="calling">
            <a href="tel:+917045527555" >
            <div >
            <img src={phone} alt='phone icon'/>
            <span>+917045527555</span>
            </div> 
              
            </a>
          </div>
        </section>
      </section>
    </header>
  );
};

export default Header;
