import React from 'react';
import './Footer.css'
const Footer = () => {
  return (
    <footer className="section-footer" >
	<div className="container">
		<section className="footer-bottom border-top row">
			<div className="col-md-2 footer">
				<p> &copy; Powered By <a href="https://kodeinnovate.in/" target="blank">Kodeinnovate</a></p>
			</div>
		</section>
	</div>
</footer>
  );
};

export default Footer;
