import React, { useState } from 'react';
import './PricePopup.css'; // Import the PricePopup CSS file
const PricePopup = ({ onClose }) => {
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    var link = `https://wa.me/917045527555?text=I'm%20interested%20in%20buying%20a%20plate`
    window.open(link, "_blank");
    console.log('Phone number submitted:', phoneNumber);
    onClose();
    
  };

  return (
    <div className="popup">
      <div className="popup-inner">
        <h2 className='heading'>Get Latest Price<br/>
          We Will Get Back To you
        </h2>
        <form onSubmit={handleSubmit}>
          <label htmlFor="phone">Enter your phone number:</label>
          <input
            type="tel"
            id="phone"
            maxLength={10}
            placeholder='Example :- 9188******88'
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
          <button type="submit">Submit</button>
        </form>
        <button className="popup-close" onClick={onClose}>X</button> {/* Close button */}
      </div>
    </div>
  );
};

export default PricePopup;
